import { axios } from '@/utils/request'

export function getWmsDataPage(type, parameter) {
  return axios({
    url: `wms/data/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function createWmsDataInfo(type, data) {
    return axios({
      url: `wms/data/${type}/create`,
      method: 'post',
      data
    })
}

export function saveWmsDataInfo(type, data) {
  return axios({
    url: `wms/data/${type}/save`,
    method: 'post',
    data
  })
}

export function updateWmsDataInfo(type, data) {
    return axios({
        url: `wms/data/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteWmsDataInfo(type, data) {
    return axios({
        url: `wms/data/${type}/delete`,
        method: 'post',
        data
    })
}

export function getWmsDataFeeList(type, params) {
  return axios({
      url: `wms/data/${type}/fee_list`,
      method: 'get',
      params
  })
}

export function getWmsBusinsessPage(type, parameter) {
  return axios({
    url: `wms/business/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function getWmsInBusinsessPage(data) {
  return axios({
    url: `wms/business/in/list_in`,
    method: 'post',
    data
  })
}

export function getWmsOutBusinsessPage(data) {
  return axios({
    url: `wms/business/out/list_out`,
    method: 'post',
    data
  })
}

export function getWmsBusinsessDetail(type, parameter) {
  return axios({
    url: `wms/business/${type}/detail`,
    method: 'get',
    params: parameter
  })
}

export function createWmsBusinessInfo(type, data) {
  return axios({
    url: `wms/business/${type}/create`,
    method: 'post',
    data
  })
}

export function saveWmsBusinessInfo(type, data) {
  return axios({
    url: `wms/business/${type}/save`,
    method: 'post',
    data
  })
}

export function updateWmsInBusinessInfo(data) {
  return axios({
    url: `wms/business/in/update_info`,
    method: 'post',
    data
  })
}

export function updateWmsOutBusinessInfo(data) {
  return axios({
    url: `wms/business/out/update_info`,
    method: 'post',
    data
  })
}

export function updateWmsBusinessInfo(type, data) {
  return axios({
      url: `wms/business/${type}/update`,
      method: 'post',
      data
  })
}

export function deleteWmsBusinessInfo(type, data) {
  return axios({
      url: `wms/business/${type}/delete`,
      method: 'post',
      data
  })
}

export function removeWmsBusinessInfo(type, data) {
  return axios({
    url: `wms/business/${type}/remove`,
    method: 'post',
    data
  })
}

export function exitWmsBusinessInfo(type, data) {
  return axios({
      url: `wms/business/${type}/exit`,
      method: 'post',
      data
  })
}

export function getWmsBusinsessRelateInfo(type, parameter) {
  return axios({
    url: `wms/business/${type}/related_info`,
    method: 'get',
    params: parameter
  })
}

export function getWmsDataOption(type) {
  return axios({
      url: `wms/data/${type}/option`,
      method: 'get'
  })
}

export function getWmsMetaPage(type, parameter) {
  return axios({
    url: `wms/meta/${type}/list`,
    method: 'get',
    params: parameter
  })
}

export function createWmsMetaInfo(type, data) {
    return axios({
      url: `wms/meta/${type}/create`,
      method: 'post',
      data
    })
}

export function updateWmsMetaInfo(type, data) {
    return axios({
        url: `wms/meta/${type}/update`,
        method: 'post',
        data
    })
}

export function deleteWmsMetaInfo(type, data) {
  return axios({
      url: `wms/meta/${type}/delete`,
      method: 'post',
      data
  })
}

export function getWmsMetaOption(type) {
  return axios({
      url: `wms/meta/${type}/option`,
      method: 'get'
  })
}

export function getWmsMetaInfo(type, params) {
  return axios({
      url: `wms/meta/${type}/info`,
      method: 'get',
      params
  })
}

export function getWmsVehicleNumber() {
  return axios({
      url: `wms/meta/vehicle/all_plate_number`,
      method: 'get'
  })
}

export function getWmsInNumber(params) { // 获取进仓编号
  return axios({
      url: `wms/business/in/left_wms_in_num`,
      method: 'get',
      params
  })
}

export function getWmsInGoods(params) { // 获取进仓编号对应货物信息
  return axios({
      url: `wms/business/in/left_goods_info`,
      method: 'get',
      params
  })
}

export function wmsInUploadDone(data) { // 卸货完成
  return axios({
      url: `wms/business/in/unload_done`,
      method: 'post',
      data
  })
}

export function wmsInStat(type, data) { // 入库统计
  return axios({
      url: `wms/business/in/${type}`,
      method: 'post',
      data
  })
}

export function exportWmsStat(type, data) {
  return axios({
    url: `wms/business/${type}/export_data`,
    method: 'post',
    data
  })
}

export function exportWmsOutSheet(params) {
  return axios({
    url: `wms/business/out/export_data`,
    method: 'get',
    params
  })
}

export function wmsOutStat(type, data) { // 出库统计
  return axios({
      url: `wms/business/out/${type}`,
      method: 'post',
      data
  })
}

export function wmsStockDetail(params) { // 库存汇总
  return axios({
      url: `wms/business/in/stock`,
      method: 'get',
      params
  })
}

export function getWmsFinancePage(type, parameter) {
  return axios({
    url: `wms/finance/${type}/list`,
    method: 'post',
    data: parameter
  })
}

export function getWmsProfitPage(parameter) {
  return axios({
    url: `wms/finance/profit/list`,
    method: 'post',
    data: parameter
  })
}

export function getWmsSaleFinancePage(data) {
  return axios({
    url: `wms/sale/finance/profit_list`,
    method: 'post',
    data
  })
}

export function wmsSaleProfitTotal(data) {
  return axios({
    url: 'wms/sale/finance/profit_total',
    method: 'post',
    data
  })
}

export function getPurchaseSaleFinancePage(params) {
  return axios({
    url: `wms/sale/finance/pay_list`,
    method: 'post',
    data: params
  })
}

export function getPurchaseSaleFinanceTotal(data) {
  return axios({
    url: `wms/sale/finance/pay_list_total`,
    method: 'post',
    data
  })
}

export function getSellSaleFinancePage(params) {
  return axios({
    url: `wms/sale/finance/charge_list`,
    method: 'post',
    data: params
  })
}

export function getSellSaleFinanceTotal(data) {
  return axios({
    url: `wms/sale/finance/charge_list_total`,
    method: 'post',
    data
  })
}

export function wmsSaleFinanceFunc(type, data) {
  return axios({
    url: `wms/sale/finance/${type}`,
    method: 'post',
    data
  })
}

// 应收对账单操作
export function wmsFinanceChargeFunc(type, parameter) {
  return axios({
    url: `wms/finance/charge/${type}`,
    method: 'post',
    data: parameter
  })
}

export function wmsFinancePayFunc(type, parameter) {
  return axios({
    url: `wms/finance/pay/${type}`,
    method: 'post',
    data: parameter
  })
}

export function wmsSaleFinanceChargeFunc(type, data) {
  return axios({
    url: `wms/sale/finance/${type}`,
    method: 'post',
    data
  })
}

export function exportWmsSaleFinance(data) {
  return axios({
    url: `wms/sale/finance/export_charge_data`,
    method: 'post',
    data
  })
}

export function exportWmsPurchase(params) {
  return axios({
    url: `wms/sale/purchase/export_purchase`,
    method: `get`,
    params
  })
}

export function exportWmsSale(parameter) {
  return axios({
    url: `wms/sale/sell/export_sell`,
    method: 'get',
    params: parameter
  })
}

// 代销单导出
export function exportWmsAgent(parameter) {
  return axios({
    url: `wms/sale/agent/export_agent`,
    method: 'get',
    params: parameter
  })
}

export function wmsSaleFinancePayFunc(type, parameter) {
  return axios({
    url: `wms/sale/finance/${type}`,
    method: 'post',
    data: parameter
  })
}

export function getChargeQrcode(data) {
  return axios({
    url: `wms/finance/charge/qrcode`,
    method: 'post',
    data
  })
}

export function wmsSalePurchaseFunc(type, data) {
  return axios({
    url: `wms/sale/purchase/${type}`,
    method: 'post',
    data
  })
}

export function wmsSaleSellFunc(type, data) {
  return axios({
    url: `wms/sale/sell/${type}`,
    method: 'post',
    data
  })
}

export function getSellQrcode(data) {
  return axios({
    url: `wms/sale/sell/qrcode`,
    method: 'post',
    data
  })
}

// 代销单现金收费
export function getAgentQrcode(data) {
  return axios({
    url: `wms/sale/agent/qrcode`,
    method: 'post',
    data
  })
}

export function getPurchaseQrcode(data) {
  return axios({
    url: `wms/sale/purchase/qrcode`,
    method: 'post',
    data
  })
}

export function billCashRecord(params) {
  return axios({
    url: `wms/sale/finance/billCashRecord`,
    method: 'get',
    params
  })
}

export function delCashDeal(params) {
  return axios({
    url: `wms/sale/finance/delCashDeal`,
    method: 'post',
    params
  })
}

export function refundCashDeal(params) {
  return axios({
    url: `wms/sale/finance/refundCashDeal`,
    method: 'post',
    params
  })
}

export function createCashQRCode(params) {
  return axios({
    url: `wms/sale/finance/createCashQRCode`,
    method: 'post',
    params
  })
}

// 查验提交到仓储
export function getWmsCmsInfoDetail(data) {
  return axios({
      url: `wms/cms/detail`,
      method: 'post',
      data
  })
}

export function updateWmsCmsInfo(data) {
  return axios({
      url: `wms/cms/update`,
      method: 'post',
      data
  })
}

export function deleteWmsCmsInfo(data) {
  return axios({
      url: `wms/cms/delete`,
      method: 'post',
      data
  })
}

export function getWmsDropBoxDetail(parameter) {
  return axios({
    url: `wms/box/in/detail`,
    method: 'get',
    params: parameter
  })
}

export function saveWmsDropBoxInfo(data) {
  return axios({
    url: `wms/box/in/save`,
    method: 'post',
    data
  })
}

export function removeWmsDropBoxInfo(data) {
  return axios({
    url: `wms/box/in/remove`,
    method: 'post',
    data
  })
}

export function exitWmsDropBoxInfo(data) {
  return axios({
    url: `wms/box/in/exit`,
    method: 'post',
    data
  })
}

export function getWmsWarehouseRentPage(data) {
  return axios({
    url: `wms/business/rent/getWmsWarehouseRentPage`,
    method: `post`,
    data
  })
}

export function saveOrUpdateWmsWarehouseRent(data) {
  return axios({
    url: `wms/business/rent/saveOrUpdateWmsWarehouseRent`,
    method: `post`,
    data
  })
}

export function removeWmsWarehouseRent(data) {
  return axios({
    url: `wms/business/rent/removeWmsWarehouseRentByIds`,
    method: 'post',
    data
  })
}

export function getWmsWarehouseRentDetailById(params) {
  return axios({
    url: 'wms/business/rent/getWmsWarehouseRentDetailById',
    method: 'get',
    params
  })
}

export function getWmsWarehouseOfficePage(data) {
  return axios({
    url: 'wms/business/office/getWmsWarehouseOfficePage',
    method: 'post',
    data
  })
}

export function saveOrUpdateWmsWarehouseOffice(data) {
  return axios({
    url: 'wms/business/office/saveOrUpdateWmsWarehouseOffice',
    method: 'post',
    data
  })
}

export function getWmsWarehouseOfficeDetailById(params) {
  return axios({
    url: 'wms/business/office/getWmsWarehouseOfficeDetailById',
    method: 'get',
    params
  })
}

export function removeWmsWarehouseOfficeByIds(data) {
  return axios({
    url: 'wms/business/office/removeWmsWarehouseOfficeByIds',
    method: 'post',
    data
  })
}

export function getWmsDropBoxRecordPage(data) {
  return axios({
    url: 'wms/box/in/list_drop',
    method: 'post',
    data
  })
}

export function getWmsBalancePage(data) {
  return axios({
    url: 'wms/balance/getBalancePage',
    method: 'post',
    data
  })
}

export function getWmsPaymentPage(data) {
  return axios({
    url: 'wms/payment/getPaymentPage',
    method: 'post',
    data
  })
}

export function confirmWmsInfo(data) {
  return axios({
    url: `wms/box/in/confirm_check`,
    method: 'post',
    data
  })
}

export function getWmsTicketInfo(params) {
  return axios({
    url: `/wms/box/in/get_ticket`,
    method: 'get',
    params
  })
}

export function delWmsTicket(params) {
  return axios({
    url: `/wms/box/in/del_ticket`,
    method: 'post',
    params
  })
}

export function printWmsTicket(params) {
  return axios({
    url: `/wms/box/in/print_wms_ticket`,
    method: 'post',
    data: params
  })
}

export function queryWeighInfos(params) {
  return axios({
    url: `/wms/box/in/queryWeighInfos`,
    method: 'get',
    params
  })
}

export function generateWmsBill(params) {
  return axios({
    url: `/wms/finance/charge/generate_bill`,
    method: 'post',
    data: params
  })
}

export function exportStatementSheet(data) {
  return axios({
    url: `wms/finance/charge/exportStatementSheet`,
    method: 'post',
    data
  })
}

export function exportPayStatementSheet(data) {
  return axios({
    url: `wms/finance/pay/exportStatementSheet`,
    method: 'post',
    data
  })
}

export function getWmsOfficePage(data) {
  return axios({
    url: `/wms/sale/office/queryPageList`,
    method: 'post',
    data
  })
}

export function statisticsOfficeTotal(data) {
  return axios({
    url: 'wms/sale/office/statisticsOfficeTotal',
    method: 'post',
    data
  })
}

export function saveOrUpdateWmsOffice(data) {
  return axios({
    url: `/wms/sale/office/saveOrUpdateOffice`,
    method: 'post',
    data
  })
}

export function getWmsOfficeDetailById(params) {
  return axios({
    url: `/wms/sale/office/getOfficeDetailById`,
    method: 'get',
    params
  })
}

export function deleteWmsOfficeByIds(data) {
  return axios({
    url: `/wms/sale/office/deleteOfficeByIds`,
    method: 'post',
    data
  })
}

export function exportWmsWarehouseProfitSheet(data) {
  return axios({
    url: `wms/finance/profit/exportProfitSheet`,
    method: 'post',
    data
  })
}

export function createWmsCashQRCode(data) {
  return axios({
    url: `wms/finance/charge/createCashQRCode`,
    method: 'post',
    data
  })
}

export function delWmsCashDeal(params) {
  return axios({
    url: `wms/finance/charge/delCashDeal`,
    method: 'post',
    params
  })
}

export function getUnChargeTicket(data) {
  return axios({
    url: `wms/box/in/get_un_charge_ticket`,
    method: 'post',
    data
  })
}

export function saveAdjustOrder(data) {
  return axios({
    url: `wms/business/adjust-order/saveAdjust`,
    method: 'post',
    data
  })
}

export function initWmsAdjust() {
  return axios({
    url: `wms/business/adjust-order/init`,
    method: 'get'
  })
}

export function pageListAdjust(data) {
  return axios({
    url: `wms/business/adjust-order/pageList`,
    method: 'post',
    data
  })
}

export function submitApplyAdjust(data) {
  return axios({
    url: `wms/business/adjust-order/submitApply`,
    method: 'post',
    data
  })
}

export function deleteAdjustOrder(data) {
  return axios({
    url: `wms/business/adjust-order/deleteAdjustOrder`,
    method: 'post',
    data
  })
}

export function getWmsDetailBySerialNo(data) {
  return axios({
    url: `wms/box/in/getDetailBySerialNo`,
    method: 'get',
    params: data
  })
}

export function exportWeighSheet(data) {
  return axios({
    url: `wms/business/in/weigh_export`,
    method: 'post',
    data
  })
}

export function exportWeighCharges(data) {
  return axios({
    url: `wms/finance/charge/exportWeighInfoSheet`,
    method: 'post',
    data
  })
}
