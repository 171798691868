<template>
  <div>
    <a-card class="card" title="基本信息" :bordered="false">
      <div slot="extra">
        <span style="margin-right: 50px">作业编号：{{ form.operate_num }}</span>
        <a-button><router-link :to="{ name: 'DropBoxList' }">取消</router-link></a-button>
        <a-divider type="vertical" />
        <a-button type="primary" @click="handleSubmit" v-preventReClick>保存</a-button>
      </div>
      <a-form-model layout="vertical" :model="form" :rules="rules" ref="form">
        <a-row :gutter="20">
          <!-- <a-col :span="6">
            <a-form-model-item label="进仓编号" prop="wms_in_num">
              <a-input v-model="form.wms_in_num" />
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item label="委托客户" prop="customer_id" :autoLink="false">
              <a-select
                showSearch
                label-in-value
                allowClear
                placeholder="请选择委托客户"
                style="width: 100%"
                :value="customerInfo"
                :filter-option="false"
                :not-found-content="fetching ? undefined : null"
                @search="getDepartmentCustomer"
                @change="changeCustomerInfo"
              >
                <template v-if="fetching" #notFoundContent>
                  <a-spin size="small" />
                </template>
                <a-select-option v-for="op in customerOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="操作时间">
              <a-date-picker v-model="form.record_time" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item label="联系电话">
              <a-input v-model="form.contact_number" />
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="操作人员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择操作人员"
                style="width: 100%"
                ref="operator_name"
                v-model="form.operator_id"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <!-- <a-col :span="6">
            <a-form-model-item label="理货员">
              <a-select
                showSearch
                :filter-option="filterOption"
                allowClear
                placeholder="请选择理货员"
                style="width: 100%"
                ref="arrange_man"
                v-model="form.arrange_man_id"
              >
                <a-select-option v-for="op in operatorOps" :key="op.value">{{ op.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col> -->
          <a-col :span="6">
            <a-form-model-item label="进场时间">
              <a-date-picker v-model="form.enter_time" @change="changeEnterTime" class="full-width"></a-date-picker>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :md="24">
            <a-form-model-item label="箱型箱号箱量">
              <template v-for="(box, index) in form.box_info_list">
                <a-input-group compact :key="index" :class="['field-item', 'box-item', 'box-append-item']">
                  <a-select showSearch placeholder="箱型" style="width: 90px" v-model="box.box_id">
                    <a-select-option v-for="op in boxOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                  <a-input v-model="box.box_num" style="width: 120px" placeholder="箱号" />
                  <a-date-picker v-model="box.enter_time" format="YYYY-MM-DD" style="width: 130px" placeholder="进场日期"></a-date-picker>
                  <a-date-picker v-model="box.exit_time" format="YYYY-MM-DD" style="width: 130px" placeholder="出场日期"></a-date-picker>
                  <a-input-number v-model="box.amount" style="width: 90px" placeholder="金额"></a-input-number>
                  <a-icon
                    type="close-circle"
                    theme="filled"
                    class="field-item_del"
                    v-if="index > 0"
                    @click="delItem('box_info_list', index)"
                  />
                  <a-button
                    type="primary"
                    v-if="index === form.box_info_list.length - 1"
                    style="width: 60px;margin-left: 10px"
                    @click="createItem('box_info_list')"
                  >更多</a-button
                  >
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="24">
          <a-col :md="24">
            <a-form-model-item label="过磅信息">
              <template v-for="(weigh, index) in form.weigh_info_list">
                <a-input-group compact :key="index" :class="['field-item', 'box-item', 'box-append-item']">
                  <a-select showSearch placeholder="重/空车" style="width: 60px" v-model="weigh.weigh_type">
                    <a-select-option v-for="op in weighTypeOps" :key="op.value">{{ op.name }}</a-select-option>
                  </a-select>
                  <a-input v-model="weigh.plate_no" style="width: 100px" placeholder="车牌号" />
                  <a-date-picker v-model="weigh.weigh_date" style="width: 110px" placeholder="过磅日期"></a-date-picker>
                  <!-- <a-date-picker v-model="weigh.exit_date" style="width: 110px" placeholder="出场日期"></a-date-picker> -->
                  <a-input-number v-model="weigh.weight" style="width: 90px" placeholder="过磅重量"></a-input-number>
                  <a-input-number v-model="weigh.amount" style="width: 70px" placeholder="金额"></a-input-number>
                  <a-icon
                    type="close-circle"
                    theme="filled"
                    class="field-item_del"
                    v-if="index > 0"
                    @click="delItem('weigh_info_list', index)"
                  />
                  <a-button
                    type="primary"
                    v-if="index === form.weigh_info_list.length - 1"
                    style="width: 60px;margin-left: 10px"
                    @click="createItem('weigh_info_list')"
                  >更多</a-button
                  >
                </a-input-group>
              </template>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="20">
          <a-col :span="12">
            <a-form-model-item label="备注">
              <a-textarea v-model="form.remark"></a-textarea>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-card>
    <a-card class="card" title="其它信息" :bordered="false">
      <a-tabs
        :activeKey="activeKey"
        @change="
          key => {
            activeKey = key
          }
        "
      >
        <a-tab-pane tab="费用信息" key="1">
          <edit-table :columns="feeColumns" :sourceData="feeData" @getTableDate="getFeeData"></edit-table>
        </a-tab-pane>
      </a-tabs>
    </a-card>
  </div>
</template>

<script>
import { getCommonOptions, getCustomer } from '@/api/common'
import { getWmsDataOption, saveWmsDropBoxInfo, getWmsDropBoxDetail } from '@/api/wms'
import { EditTable } from '@/components'
import { getFirstLetter } from '@/utils/util'
import moment from 'moment'
import preventReClick from '@/utils/preventReClick'
import debounce from 'lodash/debounce'
export default {
  components: {
    EditTable,
    preventReClick
  },
  data() {
    this.lastFetchId = 0
    this.getDepartmentCustomer = debounce(this.getDepartmentCustomer, 800)
    return {
      form: {},
      sourceForm: {
        id: null,
        operate_num: '',
        customer_id: null,
        enter_time: null,
        customer: '',
        contact_number: '',
        operator_id: null,
        box_info_list: [{ box_id: undefined, box_num: null, enter_time: null, exit_time: null, amount: null }],
        weigh_info_list: [{ weigh_type: null, plate_no: null, weigh_date: null, exit_date: null, weight: null, amount: null }],
        remark: ''
      },
      isEdit: false,
      disabled: false,
      activeKey: '1',
      // bizTypeOps: [],
      customerOps: [],
      customerInfo: undefined,
      fetching: false,
      // customerContactOps: [],
      boxOps: [],
      operatorOps: [], // 铲车工
      feeData: [],
      rules: {
        customer_id: [{ required: true, message: '请选择委托客户', trigger: 'blur' }]
      },
      feeColumns: [
        {
          title: '费用名称',
          dataIndex: 'fee_id',
          extra: {
            type: 'select',
            func: this.getCommonOptions,
            params: ['fee', { department: 1, type: 1 }],
            options: [],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '付款方式',
          dataIndex: 'charge_type',
          extra: {
            type: 'select',
            defaultValue: 1,
            options: [
              { value: 1, name: '现金' },
              { value: 2, name: '月结' }
            ],
            disabled: record => {
              return record.disabled
            }
          }
        },
        {
          title: '金额',
          dataIndex: 'amount',
          extra: {
            disabled: record => {
              return record.disabled
            }
          }
        },
        // {
        //   title: '发票号',
        //   dataIndex: 'invoice_num',
        //   extra: {
        //     disabled: record => {
        //       return !(
        //         record.fee_status === 1 ||
        //         record.fee_status === 2 ||
        //         record.fee_status === 4 ||
        //         !record.fee_status
        //       )
        //     }
        //   }
        // },
        {
          title: '交易号',
          dataIndex: 'trx_num',
          extra: {
            type: 'text'
          }
        },
        {
          title: '费用状态',
          dataIndex: 'fee_status',
          customRender: (text, record) => {
            if (this.GLOBAL.feeStatusMaps[text]) {
              return this.GLOBAL.feeStatusMaps[text].name
            }
          }
        }
      ],
      weighTypeOps: [
        { value: 'weight', name: '重' },
        { value: 'empty', name: '空' }
      ]
    }
  },
  watch: {
    $route: function(newRoute) {
      this.disabled = false
      if (!this.queryParam && newRoute.name === 'DropBoxIn') {
        // 编辑页路由监控
        this.handleFormReset()
        if (newRoute.params.id === 'create') {
          // 新增
          this.isEdit = false
          this.$route.meta.title = '新增暂落箱'
        } else {
          this.disabled = newRoute.query.disabled
          this.getEditData(newRoute.params.id)
        }
      }
    }
  },
  mounted() {
    if (this.$route.params.id !== 'create') {
      this.disabled = this.$route.query.disabled
      this.getEditData(this.$route.params.id)
    }
  },
  created() {
    this.handleFormReset()
    this.getFormBasicData()
  },
  methods: {
    getWmsDataOption,
    getCommonOptions,
    moment,
    handleFormReset() {
      for (const key in this.sourceForm) {
        this.$set(this.form, key, this.sourceForm[key])
      }
      this.form.box_info_list = [{ box_id: undefined, box_num: null, enter_time: null, exit_time: null, amount: null }]
      this.form.weigh_info_list = [{ weigh_type: null, plate_no: null, weigh_date: null, exit_date: null, weight: null, amount: null }]
      this.customerInfo = undefined
      this.feeData = []
      this.$nextTick(_ => {
        this.$refs.form.clearValidate()
      })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
        getFirstLetter(option.componentOptions.children[0].text)
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    changeEnterTime(value) {
      for (const item of this.form.box_info_list) {
        item.enter_time = this.form.enter_time
      }
      for (const item of this.form.weigh_info_list) {
        item.weigh_date = this.form.enter_time
      }
    },
    getEditData(id) {
      this.isEdit = true
      this.$route.meta.title = '编辑暂落箱'
      getWmsDropBoxDetail({ id: id }).then(v => {
        const record = v.record
        this.form = {
          ...record,
          enter_time: record.enter_time ? moment(record.enter_time, 'YYYY/MM/DD') : null,
          box_info_list: (record.box_info_list || this.sourceForm.box_info_list).map(b => {
            b.enter_time = b['enter_time'] ? moment(b['enter_time'], 'YYYY/MM/DD') : null
            b.exit_time = b['exit_time'] ? moment(b['exit_time'], 'YYYY/MM/DD') : null
            return b
          }),
          weigh_info_list: (record.weigh_info_list || this.sourceForm.weigh_info_list).map(w => {
            return w
          })
        }
        if (!this.form.box_info_list || this.form.box_info_list.length === 0) {
          this.form.box_info_list = [{ box_id: undefined, box_num: null, enter_time: null, exit_time: null, amount: null }]
        }
        if (!this.form.weigh_info_list || this.form.weigh_info_list.length === 0) {
          this.form.weigh_info_list = [{ weigh_type: null, plate_no: null, weigh_date: null, exit_date: null, weight: null, amount: null }]
        }
        this.customerInfo = {
          key: v.record.customer_id,
          label: v.record.customer
        }
        this.feeData = v.record.fee_info_list ? v.record.fee_info_list.map(f => {
              if (f.fee_status > 2 && f.fee_status !== 4) {
                f.disabled = true
              } else {
                f.disabled = false
              }
              return {
                id: f.id,
                fee_id: f.fee_id,
                charge_type: f.charge_type || null,
                amount: f.amount,
                trx_num: f.trx_num,
                fee_status: f.fee_status,
                disabled: f.disabled
              }
            }) : []
      })
    },
    getDepartmentCustomer(value) {
      this.lastFetchId += 1
      const fetchId = this.lastFetchId
      this.customerOps = []
      this.fetching = true
      getCustomer({
        department: 1,
        customerName: value,
        excludeBlack: 'Y'
      }).then(res => {
        if (fetchId !== this.lastFetchId) {
          return
        }
        this.customerOps = res
        this.fetching = false
      })
    },
    changeCustomerInfo(value) {
      this.customerInfo = value
      this.form['customer_id'] = value.key
      this.form['customer'] = value.label
      this.customerOps = []
      this.fetching = false
    },
    getFormBasicData() {
      getCommonOptions('box_info').then(v => {
        this.boxOps = v
      })
      getWmsDataOption('operator').then(v => {
        this.operatorOps = v
      })
      // getWmsMetaOption('wms_business_type').then(v => {
      //   this.bizTypeOps = v
      // })
    },
    createItem(field) {
      // 增加更多箱型
      if (field === 'box_info_list') {
        this.form.box_info_list.push({ box_id: undefined, box_num: null, enter_time: (this.form.enter_time ? this.form.enter_time : null), exit_time: null, amount: null })
      } else if (field === 'weigh_info_list') {
        this.form.weigh_info_list.push({ weigh_type: null, plate_no: null, weigh_date: null, exit_date: null, weight: null, amount: null })
      } else {
        this.form[field].push('')
      }
    },
    delItem(field, index) {
      this.form[field].splice(index, 1)
    },
    getFeeData(data) {
      this.feeData = data
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const boxInfoList = []
          const weighInfoList = []
          this.form.box_info_list.map(b => {
            if (b.box_id && b.box_id !== 0 && b.box_num) {
              const v = { ...b }
              v.enter_time = v.enter_time ? moment(v.enter_time).valueOf() : null
              v.exit_time = v.exit_time ? moment(v.exit_time).valueOf() : null
              boxInfoList.push(v)
            }
          })
          this.form.weigh_info_list.map(w => {
            if (w.weigh_type && w.plate_no) {
              const v = { ...w }
              v.weigh_date = v.weigh_date ? moment(v.weigh_date).format('YYYY-MM-DD') : null
              v.exit_date = v.exit_date ? moment(v.exit_date).format('YYYY-MM-DD') : null
              weighInfoList.push(v)
            }
          })
          const data = {
            record: {
              ...this.form,
              enter_time: this.form.enter_time ? moment(this.form.enter_time).valueOf() : null,
              box_info_list: boxInfoList,
              weigh_info_list: weighInfoList
            },
            fee_list: this.feeData.map(b => {
              const v = { ...b }
              if (v.fee_status === '') {
                v.fee_status = null
              }
              return v
            })
          }
          saveWmsDropBoxInfo(data).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: this.isEdit ? '编辑成功' : '创建成功.'
            })
            this.$router.push({
              name: 'DropBoxList',
              params: {
                ...data.record
              },
              query: {
                isEdit: this.isEdit
              }
            })
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>
.sub-model {
  width: 32%;
  margin-right: 1%;
}
.sub-model-title {
  position: relative;
  padding: 0 11px;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #d9d9d9;
  border-right: 0;
}
.sub-model .ant-input-number {
  width: calc(100% - 55px);
}

.box-append-item {
  width: 560px;
}
.box-append-item:last-child {
  width: 630px;
}
.box-append-item .field-item_del {
  left: 550px;
}
</style>
